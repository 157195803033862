/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState } from "react";

import {
  Sidebar as ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";

import { Link } from "react-router-dom";

import {
  FaRegChartBar,
  FaHome,
  FaRegUserCircle,
  FaUsersCog,
  FaPlaceOfWorship,
  FaDollarSign,
  FaFileExport,
  FaBook,
  FaSync,
} from "react-icons/fa";

import { GiSaintBasilCathedral } from "react-icons/gi";

import { FiLogOut, FiSettings } from "react-icons/fi";

import { RiAdminLine } from "react-icons/ri";

//contexts
import { useReactAuth } from "../../../contexts/hooks/AuthContext";

import api from "../../../services/api";

//assets
import Logo from "../../../assets/Logo-CRB Nacional_Logo-CRB-Nacional-branca-v.png";
import { Button } from "react-bootstrap";

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#ffffff",
      color: "#607489",
    },
    menu: {
      menuContent: "#fbfcfd",
      icon: "#0098e5",
      hover: {
        backgroundColor: "#c5e4ff",
        color: "#44596e",
      },
      disabled: {
        color: "#9fb6cf",
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: "#0b2948",
      color: "#8ba1b7",
    },
    menu: {
      menuContent: "#082440",
      icon: "#59d0ff",
      hover: {
        backgroundColor: "#00458b",
        color: "#b6c8d9",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
};

// hex to rgba converter
const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const sidebarClasses = {
  root: "ps-sidebar-root",
  container: "ps-sidebar-container",
  image: "ps-sidebar-image",
  backdrop: "ps-sidebar-backdrop",
  collapsed: "ps-collapsed",
  toggled: "ps-toggled",
  rtl: "ps-rtl",
  broken: "ps-broken",
};

export const menuClasses = {
  root: "ps-menu-root",
  menuItemRoot: "ps-menuitem-root",
  subMenuRoot: "ps-submenu-root",
  button: "ps-menu-button",
  prefix: "ps-menu-prefix",
  suffix: "ps-menu-suffix",
  label: "ps-menu-label",
  icon: "ps-menu-icon",
  subMenuContent: "ps-submenu-content",
  SubMenuExpandIcon: "ps-submenu-expand-icon",
  disabled: "ps-disabled",
  active: "ps-active",
  open: "ps-open",
};

const Aside = ({ image, collapsed, rtl, toggled, handleToggleSidebar }) => {
  const [theme, setTheme] = useState("dark");
  const [hasImage, setHasImage] = useState(false);

  const { user, signOut } = useReactAuth();

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    user.profile === "admin" ? setIsAdmin(true) : setIsAdmin(false);
  }, [user.profile]);

  function handleSignOut() {
    signOut();
  }

  const handleSync = useCallback(async () => {
    await api.get("/api/subscription/invoice/sync");
  }, []);

  const menuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(
              themes[theme].menu.menuContent,
              hasImage && !collapsed ? 0.4 : 1
            )
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(
          themes[theme].menu.hover.backgroundColor,
          hasImage ? 0.8 : 1
        ),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <ProSidebar
      image={image}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
      backgroundColor={hexToRgba(
        themes[theme].sidebar.backgroundColor,
        hasImage ? 0.9 : 1
      )}
      rootStyles={{
        color: themes[theme].sidebar.color,
      }}
    >
      <div
        style={{
          padding: "24px",
          textTransform: "uppercase",
          fontWeight: "bold",
          fontSize: 14,
          letterSpacing: "1px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <img src={Logo} alt="Logo" width="140" />
      </div>

      <Menu iconShape="round" menuItemStyles={menuItemStyles}>
        <MenuItem icon={<FaHome />} component={<Link to="/" />}>
          Home
        </MenuItem>
        <MenuItem icon={<FaRegChartBar />} component={<Link to="/dashboard" />}>
          Dashboard
        </MenuItem>

        <SubMenu
          icon={<FaUsersCog />}
          label="Usuários"
          className={!isAdmin && "hidden"}
        >
          <MenuItem
            className={!isAdmin && "hidden"}
            component={<Link to="/user" />}
          >
            Listar
          </MenuItem>

          <MenuItem
            className={!isAdmin && "hidden"}
            component={<Link to="/user-create" />}
          >
            Criar Novo
          </MenuItem>
        </SubMenu>

        <SubMenu icon={<GiSaintBasilCathedral />} label="Congregações">
          <MenuItem component={<Link to="/congregation" />}>Listar</MenuItem>
          <MenuItem component={<Link to="/congregation-create" />}>
            Nova
          </MenuItem>
          <MenuItem component={<Link to="/congregation-report" />}>
            Relatórios
          </MenuItem>
          <MenuItem component={<Link to="/congregation-setting" />}>
            Configurações
          </MenuItem>
        </SubMenu>

        <SubMenu icon={<FaPlaceOfWorship />} label="Unidades">
          <MenuItem component={<Link to="/unit" />}>Listar</MenuItem>
          <MenuItem component={<Link to="/unit-create" />}>Nova</MenuItem>
          <MenuItem component={<Link to="/unit-report" />}>Relatórios</MenuItem>
          <MenuItem component={<Link to="/unit-setting" />}>
            Configurações
          </MenuItem>
        </SubMenu>

        <MenuItem icon={<FaFileExport />} component={<Link to="/reports" />}>
          Relatórios
        </MenuItem>

        <SubMenu icon={<FaDollarSign />} label="Financeiro">
          <MenuItem component={<Link to="/finance-defaulters" />}>
            Inadimplentes
          </MenuItem>
          <MenuItem component={<Link to="/finance-compilant" />}>
            Adimplentes
          </MenuItem>
          <MenuItem component={<Link to="/finance-sends" />}>
            Etiquetas
          </MenuItem>
          <MenuItem component={<Link to="/finance-setting" />}>
            Configurações
          </MenuItem>
        </SubMenu>

        <SubMenu icon={<FaBook />} label="Convergência">
          <MenuItem component={<Link to="/convergence/subscriptions" />}>
            Assinaturas
          </MenuItem>
          <MenuItem component={<Link to="/convergence/invoices" />}>
            Faturas
          </MenuItem>
          <MenuItem component={<Link to="/convergence/new" />}>Nova</MenuItem>
          <MenuItem component={<Link to="/convergence/config" />}>
            Configurações
          </MenuItem>

          <MenuItem component={<Link to="/convergence/tag" />}>
            Etiquetas
          </MenuItem>

          <MenuItem component={<Link to="/convergence/reports" />}>
            Relatórios
          </MenuItem>

          {user.email === "darlantc@seja.info" && (
            <MenuItem>
              Sincronizar
              <Button size="sm" className="ml-2" onClick={handleSync}>
                <FaSync />
              </Button>
            </MenuItem>
          )}
        </SubMenu>

        <SubMenu
          icon={<FiSettings />}
          label="Configurações"
          className={!isAdmin && "hidden"}
        >
          <MenuItem
            className={!isAdmin && "hidden"}
            component={<Link to="/setting" />}
          >
            Listar
          </MenuItem>
        </SubMenu>

        <SubMenu icon={<RiAdminLine />} label="Admin">
          <MenuItem component={<Link to="/admin/paid-invoices" />}>
            Faturas Pagas
          </MenuItem>
        </SubMenu>
      </Menu>

      <Menu iconShape="round" menuItemStyles={menuItemStyles}>
        <SubMenu label={`Olá, ${user.name}`} icon={<FaRegUserCircle />}>
          <MenuItem>Perfil</MenuItem>
          <MenuItem>Segurança</MenuItem>
        </SubMenu>
      </Menu>

      <div
        className="sidebar-btn-wrapper"
        style={{
          padding: "20px 24px",
        }}
      >
        <button className="sidebar-btn" onClick={handleSignOut}>
          <FiLogOut />
          <span>Sair</span>
        </button>
      </div>
    </ProSidebar>
  );
};

export default Aside;
